import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Agriculture`}</p>
    <p>{`ERPNext for Agriculture
ERPNext Agriculture module helps you manage your land, crop cycles, track soil composition and diseases.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Crop Cycle
Plan your crop cycles with standard processes linked to a crop and keep track of all tasks.
Crop Management
The Crop master helps you keep plans and processes linked to a crop and track its history.
Land Management
Geo-tag all your properties and map them in a hierarchical model.
Disease Management
Keep a track of crop diseases with treatements and keep track of actions taken.
Inventory
ERPNext stock module will help you manage inventory and procurement across warehouses.
Billing and Payments
Track all things financial, including billings and payments with the ERPNext accounts module.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      